import React from "react"
import { graphql, Link } from "gatsby"
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"

const Wrapper = styled.div`
  padding: 200px 0px;
  max-width: 1200px;
  margin: auto;
  & h1 {
    text-align: center;
    text-transform: uppercase;
  }



  .accordion {
      width: 100%;
      max-width: 400px;
      border: 1px solid #ccc;
      border-radius: 5px;
      overflow: hidden;
  }

  .accordion input {
      display: none;
  }

  .accordion-label {
      display: block;
      background-color: #007bff;
      color: white;
      padding: 10px;
      cursor: pointer;
      user-select: none;
      font-weight: bold;
  }

  .accordion-content {
      background: #f8f9fa;
      padding: 0 10px;
      height: auto;
      clip-path: inset(0 0 100% 0);
      transition: clip-path 0.4s ease-in-out, opacity 0.4s ease-in-out;
      opacity: 0;
  }

  .accordion input:checked + .accordion-label + .accordion-content {
      clip-path: inset(0 0 0 0);
      opacity: 1;
  }


`
const ContentGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 30px;
  & a {
    color: #062c44;
  }
`
class SiteMapPage extends React.Component {
  render() {
    const { data } = this.props
    let carports = []
    let garages = []
    let rvCovers = []
    let barns = []
    let utilityBuildings = []
    let storageBuildings = []
    let commercialBuildings = []
    let buyingGuide = []
    let aboutProbuilt = []
    let pages = []
    data.allSitePage.edges.forEach(function(item, index) {
      if (item.node.path !== "/dev-404-page/") {
        if (item.node.path.indexOf("/carports/") > -1) {
          carports.push(item)
        } else if (item.node.path.indexOf("/garages/") > -1) {
          garages.push(item)
        } else if (item.node.path.indexOf("/rv-covers/") > -1) {
          rvCovers.push(item)
        } else if (item.node.path.indexOf("/barns/") > -1) {
          barns.push(item)
        } else if (item.node.path.indexOf("/utility-buildings/") > -1) {
          utilityBuildings.push(item)
        } else if (item.node.path.indexOf("/storage-buildings/") > -1) {
          storageBuildings.push(item)
        } else if (
          item.node.path.indexOf("/commercial-metal-buildings/") > -1
        ) {
          commercialBuildings.push(item)
        } else if (item.node.path.indexOf("/buying-guide/") > -1) {
          buyingGuide.push(item)
        } else if (item.node.path.indexOf("/about/") > -1) {
          aboutProbuilt.push(item)
        } else {
          pages.push(item)
        }
      }
    })
    return (
      <Layout location={this.props.location}>
        <SEO title="Sitemap" />
        <Wrapper>
          <div className="container">
            <div class="accordion">
                <input type="checkbox" id="accordion1" />
                <label for="accordion1" class="accordion-label">Click to Toggle</label>
                <div class="accordion-content">
                    <p>This is the accordion content. You can put anything here.</p>
                </div>
            </div>
            <h1>Sitemap</h1>
            <ContentGrid>
              <div>
                <h2>
                  <Link to="/carports/">Carports</Link>
                </h2>
                <ul>
                  {carports.map((item, index) => {
                    let array = item.node.path.split("/")
                    const l = array.length
                    const flag = array[l - 1]
                    if (flag !== "") {
                      return (
                        <li key={index}>
                          <Link to={item.node.path}>{array[l - 1]}</Link>
                        </li>
                      )
                    } else {
                      return (
                        <li key={index}>
                          <Link to={item.node.path}>{array[l - 2]}</Link>
                        </li>
                      )
                    }
                  })}
                </ul>
                <h2>
                  <Link to="/garages/">Garages</Link>
                </h2>
                <ul>
                  {garages.map((item, index) => {
                    let array = item.node.path.split("/")
                    const l = array.length
                    const flag = array[l - 1]
                    if (flag !== "") {
                      return (
                        <li key={index}>
                          <Link to={item.node.path}>{array[l - 1]}</Link>
                        </li>
                      )
                    } else {
                      return (
                        <li key={index}>
                          <Link to={item.node.path}>{array[l - 2]}</Link>
                        </li>
                      )
                    }
                  })}
                </ul>
                <h2>
                  <Link to="/rv-covers/">RV Covers</Link>
                </h2>
                <ul>
                  {rvCovers.map((item, index) => {
                    let array = item.node.path.split("/")
                    const l = array.length
                    const flag = array[l - 1]
                    if (flag !== "") {
                      return (
                        <li key={index}>
                          <Link to={item.node.path}>{array[l - 1]}</Link>
                        </li>
                      )
                    } else {
                      return (
                        <li key={index}>
                          <Link to={item.node.path}>{array[l - 2]}</Link>
                        </li>
                      )
                    }
                  })}
                </ul>
              </div>
              <div>
                <h2>
                  <Link to="/barns/">Barns</Link>
                </h2>
                <ul>
                  {barns.map((item, index) => {
                    let array = item.node.path.split("/")
                    const l = array.length
                    const flag = array[l - 1]
                    if (flag !== "") {
                      return (
                        <li key={index}>
                          <Link to={item.node.path}>{array[l - 1]}</Link>
                        </li>
                      )
                    } else {
                      return (
                        <li key={index}>
                          <Link to={item.node.path}>{array[l - 2]}</Link>
                        </li>
                      )
                    }
                  })}
                </ul>
                <h2>
                  <Link to="/utility-buildings/">Utility Buildings</Link>
                </h2>
                <ul>
                  {utilityBuildings.map((item, index) => {
                    let array = item.node.path.split("/")
                    const l = array.length
                    const flag = array[l - 1]
                    if (flag !== "") {
                      return (
                        <li key={index}>
                          <Link to={item.node.path}>{array[l - 1]}</Link>
                        </li>
                      )
                    } else {
                      return (
                        <li key={index}>
                          <Link to={item.node.path}>{array[l - 2]}</Link>
                        </li>
                      )
                    }
                  })}
                </ul>
                <h2>
                  <Link to="/storage-buildings/">Storage Buildings</Link>
                </h2>
                <ul>
                  {storageBuildings.map((item, index) => {
                    let array = item.node.path.split("/")
                    const l = array.length
                    const flag = array[l - 1]
                    if (flag !== "") {
                      return (
                        <li key={index}>
                          <Link to={item.node.path}>{array[l - 1]}</Link>
                        </li>
                      )
                    } else {
                      return (
                        <li key={index}>
                          <Link to={item.node.path}>{array[l - 2]}</Link>
                        </li>
                      )
                    }
                  })}
                </ul>
                <h2>
                  <Link to="/commercial-metal-buildings/">
                    Commercial Buildings
                  </Link>
                </h2>
                <ul>
                  {commercialBuildings.map((item, index) => {
                    let array = item.node.path.split("/")
                    const l = array.length
                    const flag = array[l - 1]
                    if (flag !== "") {
                      return (
                        <li key={index}>
                          <Link to={item.node.path}>{array[l - 1]}</Link>
                        </li>
                      )
                    } else {
                      return (
                        <li key={index}>
                          <Link to={item.node.path}>{array[l - 2]}</Link>
                        </li>
                      )
                    }
                  })}
                </ul>
                <h2>
                  <Link to="/buying-guide/">Buying Guide</Link>
                </h2>
                <ul>
                  {buyingGuide.map((item, index) => {
                    let array = item.node.path.split("/")
                    const l = array.length
                    const flag = array[l - 1]
                    if (flag !== "") {
                      return (
                        <li key={index}>
                          <Link to={item.node.path}>{array[l - 1]}</Link>
                        </li>
                      )
                    } else if (array[l - 2] !== "buying-guide") {
                      return (
                        <li key={index}>
                          <Link to={item.node.path}>{array[l - 2]}</Link>
                        </li>
                      )
                    }
                    return <></>
                  })}
                </ul>
                <h2>
                  <Link to="/about/">About Probuilt</Link>
                </h2>
                <ul>
                  {aboutProbuilt.map((item, index) => {
                    let array = item.node.path.split("/")
                    const l = array.length
                    const flag = array[l - 1]
                    if (flag !== "") {
                      return (
                        <li key={index}>
                          <Link to={item.node.path}>{array[l - 1]}</Link>
                        </li>
                      )
                    } else {
                      return (
                        <li key={index}>
                          <Link to={item.node.path}>{array[l - 2]}</Link>
                        </li>
                      )
                    }
                  })}
                </ul>
                <h2>
                  <Link>Pages</Link>
                </h2>
                <ul>
                  {pages.map((item, index) => {
                    if (
                      [
                        "/thank-you-quick-contact/",
                        "/thank-you-rto/",
                        "/thank-you-newsletter/",
                        "/thank-you-financing/",
                        "/thank-you-contact-us/",
                        "/thank-you-customize-building/",
                        "/thank-you-request-quote/",
                        "/thanks/",
                        "/404.html/",
                        "/sitemap/",
                        "/search/",
                        "/product-bk/",
                        "/product/",
                        "/404/",
                      ].includes(item.node.path)
                    ) {
                      return null
                    }
                    let array = item.node.path.split("/")
                    const l = array.length
                    const flag = array[l - 1]
                    if (
                      l < 4 &&
                      flag !== "garages" &&
                      flag !== "carports" &&
                      flag !== "barns" &&
                      flag !== "rv-covers" &&
                      flag !== "storage-buildings" &&
                      flag !== "utility-buildings" &&
                      flag !== "commercial-metal-buildings" &&
                      flag !== "thanks"
                    ) {
                      if (flag !== "") {
                        return (
                          <li key={index}>
                            <Link to={item.node.path}>{array[l - 1]}</Link>
                          </li>
                        )
                      } else if (item.node.path !== "/") {
                        return (
                          <li key={index}>
                            <Link to={item.node.path}>{array[l - 2]}</Link>
                          </li>
                        )
                      } else {
                        return (
                          <li>
                            <Link to={item.node.path}>home</Link>
                          </li>
                        )
                      }
                    }
                    return <></>
                  })}
                </ul>
              </div>
            </ContentGrid>
          </div>
        </Wrapper>
      </Layout>
    )
  }
}

export default SiteMapPage

export const pageQuery = graphql`
  query SiteMapPageQuery {
    allSitePage {
      edges {
        node {
          id
          path
        }
      }
    }
  }
`
